h1,h2,h3,h4,h5 {font-weight: bold;}

h1 {
	// color: pink;
	font-size: 50px;
	margin: 20px 0;
}

h2 {
	font-size: 42px;
	margin: 15px 0;
}
h3 {
	font-size: 30px;
	margin: 15px 0;
}

h4 {
	font-size: 23px;
	margin: 15px 0;
}