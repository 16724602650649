.main-nav {
	padding: 20px 0;
	background: #000;
	z-index: 150;
	border-bottom: 1px solid rgba(255,255,255,0.4);

	@media (min-width: 768px){
		// background: initial;
		border-bottom: none;
	}

	&_list {
		margin: 0;
		padding: 0;
		list-style: none;
		text-align: center;
		// display: none;

		@media (min-width: 768px){
			text-align: initial;
			display: block;
		}

		@media (min-width: 1024px){
			text-align: center;
		}

		li {
			display: inline-block;
			margin-right: 0px;

			a {
				padding: 0 7px;
				font-size: 20px;
				font-weight: bold;
				color: #fff;
				position: relative;
				transition: all .4s ease;

				&:after {
					content: "";
					position: absolute;
					bottom: 0;
					left: 20%;
					width: 0;
					height: 2px;
					background: $light-blue;
					transition: width .4s ease;
				}


				&:hover,
				&:focus,
				&:active {
					text-decoration: none;
					outline: none;
					transition: all .4s ease;

					&:after {
						background: $light-blue;
						width: 60%;
						position: absolute;
						bottom: 0;
						left: 20%;
						content: "";
						height: 2px;
						transition: width .4s ease;
					}

				}

				// nav links
				@media (min-width: 512px) {
					padding: 0 15px;
				}
				@media (min-width: 768px) {
					padding: 0 20px;
				}
			}

		}

		
	}



	.soc_icons {
		padding: 0;
		margin: 20px 0;
		text-align: center;
		list-style: none;

		@media (min-width: 768px){
			text-align: initial;
			margin: initial;
		}

		li {
			display: inline-block;
			margin-right: 15px;

			a {
				color: #fff;
				display: inline-block;

				img {
					width: 25px;
					height: 25px;
				}
			}
		}
	}

	.logo {
		padding: 0;
		text-align: center;
		margin: 10px 0;
		color: $light-blue;
		font-size: 20px;
		text-decoration: none;
		
		@media (min-width: 768px){
			text-align: left;
			margin: 0;
		}

		@media (min-width: 1024px){
			text-align: right;
		}

		span {
			color: #fff;
		}
	}

	// footer nav
	&_footer {
		list-style: none;
		margin: 0;
		padding: 40px 0;
		text-align: center;

		li {
			display: inline-block;
			margin: 0px;
			
			a {
				padding: 0 7px;
				font-weight: bold;
				font-size: 20px;
				opacity: 1;
				transition: all .4s ease;
				color: $black;

				@media (min-width: 512px) {
					padding: 0 15px;
				}

				@media (min-width: 768px) {
					padding: 0 20px;
				}

				&:hover,
				&:active {
					color: #333;
					text-decoration: none;
					outline: none;
					opacity: .5;
					transition: all .4s ease;
				}
			}
		}

	}

}



